import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Download, Upload } from 'components/Icons';
import DateTimePicker from 'components/inputs/DateTimePicker';
import Layout from 'components/Layout';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import Select from 'react-select';
import { useBranchId, useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { byId } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

function UploadButton(props) {
  const classes = useStyles();
  const { title, upload, href } = props;
  const [resetInput, setResetInput] = useState(1);
  return (
    <label htmlFor={href}>
      <input
        key={resetInput}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        className={classes.input}
        id={href}
        // multiple
        onChange={(e) => {
          upload(e, href);
          setResetInput((val) => val + 1);
        }}
      />
      <Button
        variant="outlined"
        color="primary"
        component="span"
        startIcon={<Upload />}
        className={classes.button}
      >
        {title}
      </Button>
    </label>
  );
}

const DownloadButton = (props) => {
  return (
    <Button
      variant="outlined"
      href={props.href}
      target="_blank"
      color="primary"
      component="a"
      {...props}
    >
      {props.title}
    </Button>
  );
};

function CustomBtn({ branchId, report, dateRange, disable, orderId, tripId, retry, type }) {
  const classes = useStyles();
  const [notif] = useSnackbar();
  const getApiParams = (option) => {
    let queryString = `?supplier_id=${branchId}`;
    const dateRangeValue = dateRange?.[option.api];
    if (option.params?.includes(ParamType.start_date) && dateRangeValue)
      queryString += `&start_date=${dateRangeValue[0]}&end_date=${dateRangeValue[1]}`;
    if (option.params?.includes(ParamType.order_id) && orderId)
      queryString += `&order_id=${orderId}`;
    if (tripId) queryString += `&trip_id=${tripId}&retry=${retry}`;
    return queryString;
  };
  const getApiPathWithQueryParams = (option, actionType, excludePrefix) => {
    const apiPath = option.api;
    let path = `/sbmart${apiPath}/${actionType}`;
    if (!excludePrefix) {
      path = `/api${path}`;
    }
    return `${path}${getApiParams(option)}`;
  };
  const handleFileUpload = async (event, path) => {
    var file = event.target.files[0];
    const data = new FormData();
    data.append('file', file);
    try {
      await fetch({
        method: 'POST',
        url: path,
        data,
      });
      notif('File successfully uploaded', { variant: 'success' });
    } catch (error) {}
  };
  return (
    <>
      {type === 'download' ? (
        <DownloadButton
          href={getApiPathWithQueryParams(report, 'download')}
          title="download"
          startIcon={<Download />}
          className={classes.button}
          disabled={disable}
        />
      ) : type === 'upload' ? (
        <UploadButton
          startIcon={<Upload />}
          className={classes.button}
          title="upload"
          href={getApiPathWithQueryParams(report, 'upload', true)}
          upload={handleFileUpload}
        />
      ) : (
        <Button
          href={getApiPathWithQueryParams(report, 'template')}
          component="a"
          className={classes.button}
          startIcon={<FileCopyIcon />}
        >
          Template
        </Button>
      )}
    </>
  );
}

function OrderCode({ report, branchId }) {
  const [orderId, setOrderId] = useState('');
  const setOrderCode = (e) => {
    setOrderId(e.target.value);
  };

  return (
    <Box justifyContent="space-between" m={2} display="flex" alignItems="center" width="60%">
      <TextField
        label="Order Code"
        placeholder="Enter Order Code"
        variant="outlined"
        value={orderId}
        size="small"
        onChange={setOrderCode}
      />
      <CustomBtn
        disable={orderId.length === 0}
        dateRange={undefined}
        orderId={orderId}
        report={report}
        branchId={branchId}
        type="download"
      />
    </Box>
  );
}
function OrderDate({ report, setDateRange, dateRange, branchId }) {
  const handleDateRange = (dateRange) => {
    setDateRange((preDateRange) => ({ ...preDateRange, [report.api]: dateRange }));
  };
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="60%" m={2}>
      <DateTimePicker onChange={handleDateRange} />
      <CustomBtn
        disable={!dateRange['/invoice'] || dateRange['/invoice'].length <= 0}
        dateRange={dateRange}
        report={report}
        branchId={branchId}
        type="download"
      />
    </Box>
  );
}
function PlannedTripDate({ report, setDateRange, dateRange, branchId }) {
  const [trips, setTrips] = useState([]);
  const [includeRetries, setIncludeRetries] = useState(false);
  const [invoiceForTrip, setInvoiceForTrip] = useState();

  const nodeId = useBranchId();

  const handleDateRange = (selectedDateRange) => {
    setDateRange((preDateRange) => ({ ...preDateRange, [report.api]: selectedDateRange }));
    selectedDateRange.length === 2 && loadTrips(selectedDateRange);
  };

  const [loadTrips] = useMutation(
    (date) =>
      fetch({
        url: `/bsbmart/trip?nodeId=${nodeId}&startDate=${date[0]}&endDate=${date[1]}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    {
      onSuccess: (data) => {
        setTrips(
          data.map((trip) => ({
            label: `${trip.name} ${trip.date}`,
            value: trip.id,
          }))
        );
      },
    }
  );

  const changeTrips = (val) => {
    setInvoiceForTrip(val.value);
  };

  useEffect(() => {
    setInvoiceForTrip();
  }, [dateRange]);

  return (
    <Box m={2}>
      <DateTimePicker onChange={handleDateRange} />
      <Box mt={3} display="flex" justifyContent="space-between" width="70%" alignItems="center">
        <div className="w-1/3">
          <Select
            value={trips?.find((el) => el.value === invoiceForTrip) || null}
            placeholder="Select Trips"
            options={trips}
            onChange={changeTrips}
          />
        </div>
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={includeRetries}
              onChange={() => setIncludeRetries((s) => !s)}
              name="retries"
            />
          }
          label="Include Retries"
        />
        <CustomBtn
          disable={!invoiceForTrip || invoiceForTrip.length <= 0}
          branchId={branchId}
          report={report}
          dateRange={dateRange}
          tripId={invoiceForTrip}
          retry={includeRetries}
          type="download"
        />
      </Box>
    </Box>
  );
}

const ParamType = {
  supplier_id: 'supplier_id',
  start_date: 'start_date',
  end_date: 'end_date',
  order_id: 'order_id',
};

const reports = [
  {
    id: 'notif',
    name: 'App Announcements',
    api: `/announcement`,
  },
  {
    id: 'pricing',
    name: 'SKU Pricing Upload',
    api: `/price`,
  },
  {
    id: 'promotion',
    name: 'Promotions Enable (Invoice + SKU)',
    api: `/promotion/enable`,
    disableDownload: true,
  },
  {
    id: 'promotionSKU',
    name: 'Promotions Upload (SKU)',
    api: `/promotion/sku`,
  },
  {
    id: 'promotionInvoice',
    name: 'Promotions Upload (Invoice)',
    api: `/promotion/invoice`,
  },
  {
    id: 'promotionLoyalty',
    name: 'Promotions Upload (Loyalty)',
    api: `/promotion/loyalty`,
  },
  {
    id: 'recommendedProducts',
    name: 'Recommended Products',
    api: `/product-recommendations`,
  },
  {
    id: 'promotionOutlet',
    name: 'Promotions Upload (Customer)',
    api: `/promotion/outlet`,
  },
  {
    id: 'inventory',
    name: 'Inventory Upload',
    api: `/inventory`,
    onlyDownload: true,
  },
  {
    id: 'inventoryRefresh',
    name: 'Inventory Upload',
    api: `/inventory`,
    disableDownload: true,
  },
  {
    id: 'inventorySnapshotTxn',
    api: `/inventory/transaction`,
    onlyDownload: true,
    params: [ParamType.start_date, ParamType.end_date],
  },
  // {
  //   id: 'receipt',
  //   api: `/inventory/gr`,
  // },
  {
    id: 'inventorySnapshotDay',
    api: `/inventory/daywise`,
    onlyDownload: true,
    params: [ParamType.start_date, ParamType.end_date],
  },
  // {
  //   id: 'order',
  //   name: 'Order Download',
  //   api: ``,
  //   onlyDownload: true,
  //   params: [ParamType.start_date, ParamType.end_date],
  // },
  {
    id: 'supplierSpec',
    name: 'Supplier Specs',
    api: `/supplier/specifications`,
  },
  // {
  //   name: 'Invoice Generation',
  //   api: `/invoice`,
  //   onlyDownload: true,
  //   params: [ParamType.start_date, ParamType.end_date],
  // },
  {
    id: 'invoice',
    name: 'Invoice Generation',
    api: `/invoice`,
    onlyDownload: true,
    params: [ParamType.start_date, ParamType.end_date, ParamType.order_id],
  },
  // {
  //   name: 'SKU Upload (principal)',
  //   api: `/sku/principal`,
  // },
  // {
  //   name: 'SKU Upload (brand)',
  //   api: `/sku/brand`,
  // },
];

const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
        color: 'white',
      },
      '& .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'green',
        zIndex: -1,
      },
    },
  },
  checked: {},
})(Checkbox);

export default function (props) {
  const { branchId } = qs.parse(props.location.search);
  const [dateRange, setDateRange] = useState({});
  const [orderId, setOrderId] = useState(undefined);
  const report =
    byId(
      reports,
      props.location.pathname
        .split('/')
        .slice(props.location.pathname.endsWith('/') ? -2 : -1)[0]
        .replace(/\/$/, '')
    ) || 0;

  const labels = [
    { name: 'By Order ID', comp: <OrderCode report={report} branchId={branchId} /> },
    {
      name: 'By Packed Date',
      comp: (
        <OrderDate
          branchId={branchId}
          report={report}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      ),
    },
    {
      name: 'By Planned Trip Date',
      comp: (
        <PlannedTripDate
          branchId={branchId}
          report={report}
          setDateRange={setDateRange}
          dateRange={dateRange}
        />
      ),
    },
  ];
  const [invoiceType, setInvoiceType] = useState(labels[1].name);
  const handleInvoiceType = (e) => {
    setInvoiceType(e.target.value);
  };

  return (
    <Layout {...props}>
      {report.id === 'invoice' && (
        <Box m={5}>
          <RadioGroup
            aria-label="downloadInvoice"
            name="downloadInvoice"
            value={invoiceType}
            onChange={handleInvoiceType}
          >
            {labels.map((label) => (
              <FormControlLabel
                key={label.name}
                value={label.name}
                control={<CustomRadio />}
                label={label.name}
              />
            ))}
          </RadioGroup>
          {labels.find((trip) => trip.name === invoiceType).comp}
        </Box>
      )}
      {report.params?.includes(ParamType.order_id) && report.id !== 'invoice' ? (
        <TextField
          id="outlined-textarea"
          label="Order Id"
          placeholder="Enter Order Id"
          variant="outlined"
          value={orderId}
          size="small"
          onChange={(event) => {
            setOrderId(event.target.value);
          }}
        />
      ) : null}
      {report.params?.includes(ParamType.start_date) && report.id !== 'invoice' ? (
        <DateTimePicker
          onChange={(dateRange) =>
            setDateRange((preDateRange) => ({ ...preDateRange, [report.api]: dateRange }))
          }
        />
      ) : null}
      {!report.onlyDownload && report.id !== 'invoice' ? (
        <>
          <CustomBtn type="template" branchId={branchId} report={report} />
          <CustomBtn type="upload" branchId={branchId} report={report} />
        </>
      ) : null}
      {!report.disableDownload && report.id !== 'invoice' ? (
        <CustomBtn
          branchId={branchId}
          report={report}
          dateRange={dateRange}
          disable={
            report.params?.includes(ParamType.start_date) && dateRange[report.api] === undefined
          }
          type="download"
        />
      ) : null}
    </Layout>
  );
}
